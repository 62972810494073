body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: #a8b1b8;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

body ::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* In Grey Backgrounds:  <--- default */
body ::-webkit-scrollbar-track {
  background: #ffffff;
  box-shadow: 0 0 3px #a8b1b880;
}

/* In White Backgrounds: */
body .cos-secondary-scrollbar ::-webkit-scrollbar-track {
  background: #edeeef;
}

/* In Dark Backgrounds: */
body .cos-dark-scrollbar ::-webkit-scrollbar-track {
  background: #ffffff;
}
