@font-face {
  font-family: 'controller';
  src: url('assets/fonts/06725377b902746127049f1019c53c75.woff');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

/* utility css */
.link-text:hover,
.link-text:focus {
  text-decoration: underline;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  outline: none;
}

input::selection {
  background: #07b9ec;
}


/* Tailwind common class */
.color-animation {
  @apply transition-[color] duration-[0.3s] ease-[ease];
}

.base-animation {
  @apply duration-[0.3s] ease-[ease];
}

.cos-link {
  @apply text-artist-blue-900 no-underline outline-none hover:underline focus:underline
}

/* OTher Css */
.modaldialog {
  background-color: #edeeef;

  .MuiDialogContent-root {
    width: 638px;
    height: 343px;
    background: #edeeef;
  }
}

.question svg path {
  stroke: #eb5050;
}

.hoverColor:hover {
  color: #07b9ec;
}

.hoverColor:hover svg path {
  fill: #07b9ec;
  stroke: #07b9ec;
}

.Sizingforlogo {
  width: 324px;
  height: auto;
}

footer.font-size {
  font-size: 12px;
}

.mb-41 {
  margin-bottom: 40px;
}

.language-dropdown svg {
  rotate: 180deg;

  path {
    fill: #07b9ec;
  }
}

@media (max-width: 1024px) {
  .lg-fixed-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background-color: #ffffff;
  }
}

@media (max-width: 700px) {
  .int-sx-device .MuiInputBase-input {
    text-align: left;
  }

  .modaldialog {
    background-color: #edeeef;

    .MuiDialogContent-root {
      width: 100%;
      height: auto;
      text-align: center;
      padding: 20px 24px;
    }
  }

  .dialogsetting {
    .MuiDialog-container {
      .MuiPaper-root {
        height: 100%;
      }
    }
  }
}

@media (max-width: 394px) {
  .Sizingforlogo {
    width: 100%;
  }
}

@media (min-width: 470px) and (max-width: 639px) {
  .custom-display {
    display: flex;
  }
}

@media (max-width: 470px) {
  .custom-display {
    display: grid;
  }

  .custom-display img {
    margin: 0 auto;
  }
}